module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/render/project/src/src/cms/cms.js","enableIdentityWidget":true,"htmlTitle":"Gatsby Starter Business Content Manager"},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00d1b2","showSpinner":false},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"","includeInDevelopment":false},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Afloat Inc.","short_name":"AI for streaming Business","start_url":"/index.html","background_color":"#ffffff","theme_color":"#00d1b2","display":"standalone","icons":[{"src":"/icons/icon-192x192.jpg","sizes":"192x192","type":"image/png"},{"src":"/icons/icon-512x512.jpg","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
